a[href]{@apply text-blue-900
    hover:text-blue-600
    visited:text-blue-900
    dark:text-blue-400
    hover:dark:text-blue-300
    visited:dark:text-blue-400}button{@apply cursor-pointer}#__next>footer{@apply py-4
    px-6
    flex
    mx-auto
    text-sm
    container
    justify-between}#__next>footer .left,#__next>footer .right{@apply flex items-center}#__next>.header{@apply z-10
    fixed
    top-0
    left-0
    shadow
    right-0
    bg-white
    dark:bg-gray-800}#__next>.header .wrapper{@apply px-6
      flex
      mx-auto
      container
      justify-between}#__next>.header .wrapper h1{@apply py-4}#__next>.header .wrapper .menu{@apply flex gap-x-8}#__next>.header .wrapper .menu li{@apply flex}#__next>.header .wrapper .menu li a{@apply flex
            items-center
            border-blue-900
            dark:border-blue-400
            hover:border-blue-600
            hover:dark:border-blue-300}#__next>.header .wrapper .menu li.active a{@apply border-b}#__next>.container>.filters{@apply flex
    mb-4
    pl-6
    z-10
    shadow
    relative
    bg-white
    dark:bg-gray-800}#__next>.container>.filters .genders{@apply flex
    ml-6
    flex-row
    leading-none}#__next>.container>.filters .genders i{@apply flex
        ml-2
        text-2xl
        leading-5
        last:mr-4
        items-center
        cursor-pointer
        text-gray-300
        dark:text-gray-600}#__next>.container>.filters .genders i.active{@apply text-blue-900
          dark:text-blue-400}#__next>.container>.filters .rc-slider{@apply flex-1 pt-6 pb-9}#__next>.container>.filters .rc-slider .rc-slider-rail{@apply bg-gray-300
      dark:bg-gray-600}#__next>.container>.filters .rc-slider .rc-slider-track{@apply bg-blue-900
        dark:bg-blue-400}#__next>.container>.filters .rc-slider .rc-slider-handle{@apply bg-white
        border-blue-900
        dark:bg-gray-600
        dark:border-blue-400}#__next>.container>.filters .rc-slider .rc-slider-step .rc-slider-dot{@apply bg-white
          border-gray-300
          dark:bg-gray-600
          dark:border-gray-500}#__next>.container>.filters .rc-slider .rc-slider-step .rc-slider-dot-active{@apply border-blue-900
          dark:border-blue-400}#__next>.container>.filters .rc-slider .rc-slider-mark{@apply leading-none relative top-3}#__next>.container>.filters .rc-slider .rc-slider-mark .rc-slider-mark-text{@apply text-gray-400
          dark:text-gray-600}#__next>.container>.filters .rc-slider .rc-slider-mark .rc-slider-mark-text-active{@apply text-gray-900
          dark:text-gray-300}.overview{@apply p-4
    shadow
    bg-white
    dark:bg-gray-800;height:calc(100vh - 14rem)}#__next>.dashboard{@apply grid
    px-6
    gap-4
    mt-24
    mx-auto
    container
    sm:grid-cols-2
    md:grid-cols-2
    lg:grid-cols-3
    xl:grid-cols-4
    2xl:grid-cols-5}#__next>.dashboard .tile{@apply p-4
      grid
      shadow
      bg-white
      grid-cols-3
      dark:bg-gray-800
      grid-flow-col-dense}#__next>.dashboard .tile .right{@apply col-span-2}#__next>.dashboard .tile .left .year-trend{@apply flex
          mb-2
          text-xl
          font-bold
          items-center}#__next>.dashboard .tile .left .year-trend i{@apply text-2xl}#__next>.dashboard .tile .left .year-trend i.ri-arrow-right-down-line{@apply text-green-600}#__next>.dashboard .tile .left .year-trend i.ri-arrow-right-up-line{@apply text-red-600}#__next>.dashboard .tile .left .stat{@apply mb-2
          last:mb-0}#__next>.dashboard .tile .left .stat .label{@apply text-xs}#__next>.dashboard .tile .left .stat .value{@apply leading-tight}#__next>.dashboard .tile .left .stat .value.above{@apply text-blue-900 dark:text-blue-400}#__next>.dashboard .tile .left .stat .value.below{@apply text-green-900 dark:text-green-400}.comparison{@apply flex
    flex-col;height:calc(100vh - 14rem)}.comparison .chart{@apply p-4
      flex-1
      shadow
      bg-white
      dark:bg-gray-800}.comparison .years{@apply p-4
      mt-4
      flex
      gap-2
      shadow
      flex-wrap
      bg-white
      dark:bg-gray-800}.comparison .years li button{@apply px-2
          py-0.5
          rounded
          bg-gray-900
          hover:bg-gray-900}.comparison .years li button.active{@apply text-gray-800}.distribution{@apply p-4
    shadow
    bg-white
    dark:bg-gray-800;height:calc(100vh - 14rem)}