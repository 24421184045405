.comparison {
  @apply
    flex
    flex-col;

  height: calc(100vh - 14rem);

  .chart {
    @apply
      p-4
      flex-1
      shadow
      bg-white
      dark:bg-gray-800;
  }

  .years {
    @apply
      p-4
      mt-4
      flex
      gap-2
      shadow
      flex-wrap
      bg-white
      dark:bg-gray-800;

    li {
      button {
        @apply
          px-2
          py-0.5
          rounded
          bg-gray-900
          hover:bg-gray-900;

        &.active {
          @apply text-gray-800;
        }
      }
    }
  }
}
