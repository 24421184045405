#__next > .header { /* stylelint-disable-line */
  @apply
    z-10
    fixed
    top-0
    left-0
    shadow
    right-0
    bg-white
    dark:bg-gray-800;

  .wrapper {
    @apply
      px-6
      flex
      mx-auto
      container
      justify-between;

    h1 {
      @apply py-4;
    }

    .menu {
      @apply flex gap-x-8;
  
      li {
        @apply flex;

        a {
          @apply
            flex
            items-center
            border-blue-900
            dark:border-blue-400
            hover:border-blue-600
            hover:dark:border-blue-300;
        }

        &.active {
          a {
            @apply border-b;
          }
        }
      }
    }
  }
}
