#__next > .container > .filters { /* stylelint-disable-line */
  @apply
    flex
    mb-4
    pl-6
    z-10
    shadow
    relative
    bg-white
    dark:bg-gray-800;

  .genders {
    @apply
    flex
    ml-6
    flex-row
    leading-none;

    i {
      @apply
        flex
        ml-2
        text-2xl
        leading-5
        last:mr-4
        items-center
        cursor-pointer
        text-gray-300
        dark:text-gray-600;
      
      &.active {
        @apply
          text-blue-900
          dark:text-blue-400;
      }
    }
  }

  .rc-slider {
    @apply flex-1 pt-6 pb-9;

    .rc-slider-rail {
      @apply
      bg-gray-300
      dark:bg-gray-600;
    }

    .rc-slider-track {
      @apply
        bg-blue-900
        dark:bg-blue-400;
    }

    .rc-slider-handle {
      @apply
        bg-white
        border-blue-900
        dark:bg-gray-600
        dark:border-blue-400;
    }

    .rc-slider-step {
      .rc-slider-dot {
        @apply
          bg-white
          border-gray-300
          dark:bg-gray-600
          dark:border-gray-500;
      }

      .rc-slider-dot-active {
        @apply
          border-blue-900
          dark:border-blue-400;
      }
    }

    .rc-slider-mark {
      @apply leading-none relative top-3;

      .rc-slider-mark-text {
        @apply
          text-gray-400
          dark:text-gray-600;
      }

      .rc-slider-mark-text-active {
        @apply
          text-gray-900
          dark:text-gray-300;
      }
    }
  }
}
