#__next > footer { /* stylelint-disable-line */
  @apply
    py-4
    px-6
    flex
    mx-auto
    text-sm
    container
    justify-between;

  .left, .right {
    @apply flex items-center;
  }
}
