#__next > .dashboard { /* stylelint-disable-line */
  @apply
    grid
    px-6
    gap-4
    mt-24
    mx-auto
    container
    sm:grid-cols-2
    md:grid-cols-2
    lg:grid-cols-3
    xl:grid-cols-4
    2xl:grid-cols-5;

  .tile {
    @apply
      p-4
      grid
      shadow
      bg-white
      grid-cols-3
      dark:bg-gray-800
      grid-flow-col-dense;

    .right {
      @apply col-span-2;
    }

    .left {
      .year-trend {
        @apply
          flex
          mb-2
          text-xl
          font-bold
          items-center;
        
        i {
          @apply text-2xl;

          &.ri-arrow-right-down-line {
            @apply text-green-600;
          }

          &.ri-arrow-right-up-line {
            @apply text-red-600;
          }
        }
      }

      .stat {
        @apply
          mb-2
          last:mb-0;

        .label {
          @apply text-xs;
        }

        .value {
          @apply leading-tight;

          &.above {
            @apply text-blue-900 dark:text-blue-400;
          }

          &.below {
            @apply text-green-900 dark:text-green-400;
          }
        }
      }
    }
  }
}
